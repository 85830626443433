<script setup>
import {useRouter} from "vue-router";
import {Notify} from "vant";
import dd from "gdt-jsapi"//专有钉钉
const urlParam = getUrlParam();
const router = useRouter()



const toNavigation = () => {
  router.push({
    path: '/workorder/prepare',
    query: urlParam
  })
}
const scanCode = () => {
  dd.scan({type:"qr"}).then(res=>{

    // 原生js跳转
    window.location.href = res.text

  })
}

</script>

<template>
  <div class="repair_tool">
    <div class="tool_icon" @click="scanCode">
      <img src="@/asset/image/scan-code-repair.png" alt="">
    </div>
    <div class="tool_icon" @click="toNavigation">
      <img src="@/asset/image/hand-repair.png" alt="">
    </div>
  </div>
</template>

<style scoped>
.repair_tool{
  background: #f8f8f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.tool_icon{width: 90%}
.tool_icon img{display: block;width: 100%}
</style>